<template>
  <div
    class="
      fullscreen
      bg-primary
      text-white text-center
      p-3
      d-flex
      justify-content-center
      align-items-center
    "
  >
    <div>
      <div style="font-size: 30vh">404</div>

      <div class="h2" style="opacity: 0.4">Oops. Sayfa bulunamadı...</div>

      <button class="btn btn-lg btn-outline-light mt-5" @click="backToPage">
        Bir önceki sayfaya geri dön
      </button>
    </div>
  </div>
</template>

<script>
import router from "@/router";
export default {
  name: "404",
  methods: {
    backToPage() {
      router.go(-1);
    },
  },
};
</script>
